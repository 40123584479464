import axios from "axios";
import commonActions, { SetWorkItemDetailData, SetWorkItemListData, SetDTNListData } from "../../common.actions";
import getApiUrl from "../../helpers/api-urls";
import { getApiUniqueRequestId } from "../../helpers/util-common";
export var getWorkItemListAction = function (dispatch, data, callback) {
    dispatch(commonActions.setLoader(true));
    var url = getApiUrl("documents", "getWorkItemList");
    axios
        .post(url, data)
        .then(function (response) {
        var uniqueRequestId = getApiUniqueRequestId(url);
        if (response.data.status) {
            callback(response.data.content);
            dispatch(SetWorkItemListData(response.data.content));
            dispatch(commonActions.setLoader(false));
        }
        else {
            // Handle error case
            dispatch(SetWorkItemListData({ hasError: true }));
            dispatch(commonActions.setMessage(true, response.data.message, uniqueRequestId));
        }
    })
        .catch(function (err) {
        var uniqueRequestId = getApiUniqueRequestId(url);
        dispatch(commonActions.setLoader(false));
        dispatch(commonActions.setMessage(true, "7025", uniqueRequestId));
    });
};
export var getDTNListAction = function (dispatch, data) {
    dispatch(commonActions.setLoader(true));
    var url = getApiUrl("workItems", "getDTNList");
    axios
        .post(url, data)
        .then(function (response) {
        var uniqueRequestId = getApiUniqueRequestId(url);
        if (response.data.status) {
            dispatch(SetDTNListData(response.data.content));
            dispatch(commonActions.setLoader(false));
        }
        else {
            // Handle error case
            dispatch(SetDTNListData({ hasError: true }));
            dispatch(commonActions.setMessage(true, response.data.message, uniqueRequestId));
        }
    })
        .catch(function (err) {
        var uniqueRequestId = getApiUniqueRequestId(url);
        dispatch(commonActions.setLoader(false));
        dispatch(commonActions.setMessage(true, "7025", uniqueRequestId));
    });
};
export var getWorkItemDetailAction = function (dispatch, workItemNumber) {
    dispatch(commonActions.setLoader(true));
    var url = "".concat(getApiUrl("documents", "getWorkDetailList")).concat(workItemNumber);
    axios
        .get(url)
        .then(function (response) {
        var uniqueRequestId = getApiUniqueRequestId(url);
        if (response.data.content && response.data.content.WorkItemId) {
            dispatch(SetWorkItemDetailData(response.data.content));
            dispatch(commonActions.setLoader(false));
        }
        else {
            dispatch(SetWorkItemListData({ hasError: true }));
            dispatch(commonActions.setMessage(true, response.data.message, uniqueRequestId));
        }
    })
        .catch(function (err) {
        var uniqueRequestId = getApiUniqueRequestId(url);
        dispatch(commonActions.setLoader(false));
        dispatch(commonActions.setMessage(true, "7025", uniqueRequestId));
    });
};
export var getStandardTypeListAction = function (dispatch, callback) {
    var url = getApiUrl('documents', 'getStandardTypeList');
    dispatch(commonActions.setLoader(true));
    axios.get(url)
        .then(function (response) {
        dispatch(commonActions.setLoader(false));
        callback(response.data.content);
    })
        .catch(function (err) {
        dispatch(commonActions.setMessage(true, '9076'));
    });
};

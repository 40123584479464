import { lazy } from "react";
export var customeRoutes = {
    // Will provide valid name for the component in future.
    DashBoard: lazy(function () { return import("../pages/dashboard/Dashboard"); }),
    Ballots: lazy(function () { return import("../pages/ballots/containers/Ballots.Container"); }),
    BallotDetails: lazy(function () { return import("../pages/ballots/containers/BallotDetails.Container"); }),
    WorkItem: lazy(function () { return import("../pages/WorkItems/WorkItem"); }),
    WorkItemDetail: lazy(function () { return import("../pages/WorkItemDetails"); }),
    Invalid: lazy(function () { return import("../../src/shared-components/invalid-url/InvalidUrl"); }),
    // Below mentioned code will be removed in future
    Ballot: lazy(function () { return import("../pages/Ballot/Ballot"); }),
};

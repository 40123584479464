var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import lodash from "lodash";
import { SET_MESSAGE, SET_LOADER, SHOW_ERROR_POPUP, SHOW_ALERT_POPUP, SET_MINI_LOADER, SHOW_NOT_FOUND_PAGE, HIDE_NOT_FOUND_PAGE, ALLOW_PERMISSION_FOR_PAGE, IS_SAFARI_AUTOFILL_VISISBLE, STORE_CURRENT_LOCATION, SHOW_SUCCESS_MESSAGE, SHOW_DISMISSIBLE_MESSAGE, EXPORT_BALLOTS_LIST, SET_GLOBAL_SEARCH_TEXT, SET_BALLOT_ADVANCE_SEARCH, SET_BALLOT_ADVANCE_FORM_DATA, SET_BALLOT_DETAILS, SET_BALLOT_DETAIL_TAG, SET_SELECTED_BALLOT_STATUS, SET_QUICK_SEARCH_SELECTED_USER, SET_QUICK_SEARCH_SELECTED_LEVEL, SET_IS_USER_OFFICER, SET_WORK_ITEM_LIST, SET_DTN_LIST, SET_WORK_ITEM_DETAIL } from "../../action-type";
import { allowPermissionForAuth, getModulesAdditionalPrivilege } from "../../helpers/util-common";
import { PAGE_CONFIG } from "../../models/navigation.models";
var initialState = {
    code: null,
    loading: false,
    miniLoader: false,
    showToastMessage: false,
    showSuccessToastMesage: false,
    showDismissibleToastMessage: false,
    showErrorPopup: false,
    errorMessages: [],
    isShowConfirmationPopup: false,
    currentRequest: "",
    statusList: [], // Moved this list in common as it is master data for renewal task module and will not change
    showAlertPopup: false,
    alertMessage: "",
    showNotFoundPage: false,
    userPermission: null,
    PAGE_CONFIG: PAGE_CONFIG,
    appUserList: [],
    isSafariAutoFillInfoVisible: false,
    isDocumentInfo: false,
    message: "",
    dismissibleMessage: "",
    uniqueRequestId: "",
    exportBallotList: false,
    globalSearchText: "",
    advanceBallotSearch: {},
    ballotAdvanceSearch: {},
    ballotDetailTag: 0,
    ballotDetailsData: {},
    selectedQuickSearchBallotStatus: "All",
    quickSearchSelectedUser: "",
    quickSearchSelectedLevel: [],
    ballotAdvanceSearchFormData: {},
    workItemList: {},
    workItemDetailData: {},
    dtnListData: {}
};
// Validate All Permission [GET,PUT,DELETE,POST]
export var validateAllPermission = function (request) {
    var pagePermission = {};
    var selectedUrl = lodash(request).get("Input") && lodash(request).get("Input");
    var groupUrl = lodash(request).get("groupInput") && lodash(request).get("groupInput");
    var additionalPermissionUrl = getModulesAdditionalPrivilege(selectedUrl);
    if (groupUrl && groupUrl.length > 0) {
        var groupFilter = groupUrl.filter(function (res) { return res.componentElementOnPage.indexOf(selectedUrl.uniqueKey) > -1; });
        if (groupFilter && groupFilter.length === 0 && additionalPermissionUrl && additionalPermissionUrl.length > 0) {
            // Push additional & current page If Component Element on Page get Empty
            groupFilter.push.apply(groupFilter, __spreadArray([selectedUrl], additionalPermissionUrl, false));
        }
        if (groupFilter && groupFilter.length > 0) {
            // Push additional & current page Url.
            groupFilter.push.apply(groupFilter, __spreadArray([selectedUrl], additionalPermissionUrl, false));
            groupFilter.map(function (modules) {
                var isPageAuthorized = /* filterPermissionUrl(modules, modules.oslMethod) */ true;
                var isAllow = allowPermissionForAuth(isPageAuthorized);
                if (isAllow) {
                    pagePermission[modules.uniqueKey] = true;
                }
            });
        }
    }
    return pagePermission;
};
var getCurrentModule = function (currentModuleData) {
    if (currentModuleData && currentModuleData.Input) {
        return currentModuleData.Input.title;
    }
    return "";
};
var reducerCommon = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case SET_MESSAGE:
            return __assign(__assign({}, state), { code: action.code, showToastMessage: action.flag, message: action.message, uniqueRequestId: action.uniqueRequestId });
        case SHOW_SUCCESS_MESSAGE:
            return __assign(__assign({}, state), { code: action.code, showSuccessToastMesage: action.flag, message: action.message });
        case SHOW_DISMISSIBLE_MESSAGE:
            return __assign(__assign({}, state), { showDismissibleToastMessage: action.flag, dismissibleMessage: action.dismissibleMessage });
        case SET_LOADER:
            return __assign(__assign({}, state), { loading: action.flag });
        case SHOW_ERROR_POPUP:
            return __assign(__assign({}, state), { showErrorPopup: action.flag, errorMessages: action.data });
        case SHOW_ALERT_POPUP:
            return __assign(__assign({}, state), { showAlertPopup: action.flag, alertMessage: action.data });
        case SET_MINI_LOADER:
            return __assign(__assign({}, state), { miniLoader: action.flag });
        case SHOW_NOT_FOUND_PAGE:
            return __assign(__assign({}, state), { showNotFoundPage: true });
        case HIDE_NOT_FOUND_PAGE:
            return __assign(__assign({}, state), { showNotFoundPage: false });
        case ALLOW_PERMISSION_FOR_PAGE:
            return __assign(__assign({}, state), { userPermission: validateAllPermission(action), PAGE_CONFIG: __assign({}, PAGE_CONFIG), currentModuleTitle: getCurrentModule(action) });
        case IS_SAFARI_AUTOFILL_VISISBLE:
            return __assign(__assign({}, state), { isSafariAutoFillInfoVisible: action.flag });
        case STORE_CURRENT_LOCATION:
            return __assign(__assign({}, state), { currentRequest: action.data });
        case EXPORT_BALLOTS_LIST:
            return __assign(__assign({}, state), { exportBallotList: action.data });
        case SET_GLOBAL_SEARCH_TEXT:
            return __assign(__assign({}, state), { globalSearchText: action.data });
        case SET_BALLOT_ADVANCE_SEARCH:
            return __assign(__assign({}, state), { ballotAdvanceSearchFormData: action.data });
        case SET_BALLOT_ADVANCE_FORM_DATA:
            return __assign(__assign({}, state), { ballotAdvanceSearchFormData: action.data });
        case SET_BALLOT_DETAILS:
            return __assign(__assign({}, state), { ballotDetailsData: action.data });
        case SET_BALLOT_DETAIL_TAG:
            return __assign(__assign({}, state), { ballotDetailTag: action.data });
        case SET_SELECTED_BALLOT_STATUS:
            return __assign(__assign({}, state), { selectedQuickSearchBallotStatus: action.data });
        case SET_QUICK_SEARCH_SELECTED_USER:
            return __assign(__assign({}, state), { quickSearchSelectedUser: action.data });
        case SET_QUICK_SEARCH_SELECTED_LEVEL:
            return __assign(__assign({}, state), { quickSearchSelectedLevel: action.data });
        case SET_IS_USER_OFFICER:
            return __assign(__assign({}, state), { isUserOfficer: action.data });
        case SET_WORK_ITEM_LIST:
            return __assign(__assign({}, state), { workItemList: action.data });
        case SET_WORK_ITEM_DETAIL:
            return __assign(__assign({}, state), { workItemDetailData: action.data });
        case SET_DTN_LIST:
            return __assign(__assign({}, state), { dtnListData: action.data });
        default:
            return state;
    }
};
export default reducerCommon;
